import type { Performer, Photo, Video, SimplePerformer } from '@/ontology/performer';
import { get, post } from '../fetch-extension';
import type { Response, Paged } from '../response';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from './request';
import { transformPerformer, transformPerformers, type Filter, type Performers } from './utils';
import type { Performer as ApiPerformer } from './model';

export async function  getListPerformers(query: Filter = { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT }): Promise<Response<Paged<Performer>>> {
    const response = await get<Performers>('performer/performer_accounts', {
        query: query
    });

    const { offset } = query;

    return transformPerformers(response, offset);
}

//just an alias really
export async function searchListPerformers(query: Filter): Promise<Response<Paged<Performer>>> {
    return await getListPerformers(query);
}

//just an alias really
export async function getCategoryListPerformers(query: Filter): Promise<Response<Paged<Performer>>> {
    return await getListPerformers(query);
}

export async function getRecommended(
    query = { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT },
    relatedTo: number
): Promise<Response<Paged<Performer>>> {
    Object.assign(query, { relatedTo });
    const response = await get<Performers>('performer/performer_accounts/recommended', {
        query
    });

    const { offset } = query;

    return transformPerformers(response, offset);
}

export async function getPeekers(query: Filter, relatedTo?: number) {
    return getBusy(query, 0, relatedTo);
}

export async function getTeasers(query: Filter, relatedTo?: number) {
    return getBusy(query, 2, relatedTo);
}

export async function getBusy(query: Filter = { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT }, voyeur: 0 | 1 | 2 = 0, relatedTo?: number): Promise<Response<Paged<Performer>>> {
    //https://www.thuis.nl/api/performer/performer_accounts/busy?limit=20&offset=0&performer=2875&voyeur=1
    Object.assign(query, { relatedTo, voyeur });
    const response = await get<Performers>('performer/performer_accounts/busy', { query });
    return transformPerformers(response, query.offset);
}

interface PerformerResponse {
    performerAccount: ApiPerformer;
    photos?: {
        approved: {
            total: number;
            photos: Photo[];
        };
    };
    medias?: {
        approved: {
            total: number;
            medias: Video[];
        };
    };
}

//right now, we do not use pagination. Assume all photos and media are fetched at once..
export async function getByAdvert(advert: number, withMedia: 'free' | 'paid' | 'both' = 'both'): Promise<Response<Performer>> {
    const query = {
        paid: ['free', 'paid', 'both'].indexOf(withMedia),
        offset: 0,
        limit: 50
    };

    const { error, result } = await get<PerformerResponse>(`/performer/performer_accounts/performer_number/${advert}`, { query });

    if (error) {
        return { error };
    }

    const { performerAccount, photos, medias } = result!;

    const performer = transformPerformer(performerAccount);
    if (photos) {
        performer.photos = photos.approved.photos;
    }
    if (medias) {
        performer.media = medias.approved.medias;
    }

    return {
        result: performer
    };
}


//right now, we do not use pagination. Assume all photos and media are fetched at once..
export async function getMediaByAdvert(advert: number, withMedia: 'free' | 'paid' | 'both' = 'both'): Promise<Response<any>> {
    const query = {
        paid: ['free', 'paid', 'both'].indexOf(withMedia),
        offset: 0,
        limit: 50
    };

    const { error, result } = await get<any>(`/performer/performer_accounts/performer_number/${advert}/media`, { query });

    if (error) {
        return { error };
    }

    const { photos, medias } = result!;

    return { result: { photos: photos.approved.photos, medias: medias.approved.medias } }
}

export async function getById(id: number): Promise<Response<Performer>> {
    const { error, result } = await get<PerformerResponse>(`/performer/performer_accounts/${id}`);

    if (error) {
        return { error };
    }

    const { performerAccount } = result!;

    return {
        result: transformPerformer(performerAccount)
    };
}

export async function listUsernames(): Promise<Response<SimplePerformer[]>>  {
    const query = {
        extra: 1,
        service: 'email'
    };

    return get<SimplePerformer[]>('/performer/performer_accounts/usernames', { query });
}

export async function postMemo(reason: Object): Promise<Response<any>> {
    const { error, result } = await post('/performer/performer_account/1/memo', {
        body: JSON.stringify(reason)
    });

    if (error) {
        return { error };
    }

    if (!result) {
        throw new Error('impossible');
    }

    return { result: result };
}