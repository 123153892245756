import { defineStore } from 'pinia';
import config from '@/config';
import notifications from '@/socket';


import { getMediaByAdvert } from '@/api/performer';
import { useUserStore } from './user';
import { reArrangeMedia } from '@/utils';


interface State {
    loading: boolean;
    currentMedia: any | undefined;
    mediaList: any;
}

//let's see what else is needed here.. maybe some history?
export const useMediaStore = defineStore('media', {
    state: (): State => ({
        loading: false,
        currentMedia: undefined,
        mediaList: [], 
    }),
    actions: {
        async loadMedia(advertNumber: number){
            let excist = this.mediaList.findIndex((m: any) => m.advert === advertNumber);
            this.currentMedia = advertNumber;

            const neww = excist === -1;
            const aged = excist === 0 && age(this.mediaList[excist].fetchedAt) > config.GridCache;
            if(!neww && !aged){ return;}

            this.setMedia(aged);
        },
        async setMedia(aged: boolean = false) {
            const { error, result } = await getMediaByAdvert(this.currentMedia);
            if (error || !result) {
                return;
            }

            if(aged){
                let known = this.mediaList.findIndex((m: any) => m.advert === this.currentMedia);
                this.mediaList[known].photos = result.photos;
                this.mediaList[known].medias = result.medias;
                this.mediaList[known].fetchedAt = Date.now();
            } else {
                this.mediaList.push({advert: this.currentMedia, fetchedAt: Date.now(), photos: result.photos, medias: result.medias});
            }
        },
        async resetMedia(){
            this.currentMedia = undefined;
            this.mediaList = [];
        }
    },
    getters: {
        getCurrentMedia(state: any) {
            let known = state.mediaList.findIndex((m: any) => m.advert === state.currentMedia);
            if(known === -1){ 
                return [];
            }

            const list = reArrangeMedia(state.mediaList[known].photos.map((obj: any) => ({ ...obj, type: 'photo' })), 
            !useUserStore().safeMode ? state.mediaList[known].medias.map((obj: any) => ({ ...obj, type: 'video' })) : []);

            return list;
        },
        getCurrentSliderMedia(state: any) {
            return state.getCurrentMedia.filter((media: any) => media.placeholder != true);
        },
    }
});

function age(moment: number): number {
    return (Date.now() - moment) / 1000;
}

